<template>
  <div class="data-page">
    <header>
      <m-page-header type="link" title="配置基础信息"></m-page-header>
    </header>
    <main>
      <el-form :model="formData" label-position="top" :rules="formRule" ref="formInstance">
        <div class="form-module">
          <div class="module-title">基础信息</div>
          <div class="inputs-wrap">
            <m-form-skeleton :loading="pageLoading" :count="2">
              <el-form-item label="域名" prop="domain_name">
                <el-tooltip
                  content="关联条款：About US、Privacy Policy、Terms of Use"
                  effect="light"
                  placement="right"
                  style="left: 42px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input v-model="formData.domain_name"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-tooltip
                  content="关联条款：Contact Us、Privacy Policy、Return Policy、Terms of Use"
                  effect="light"
                  placement="right"
                  style="left: 42px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input v-model="formData.email"></el-input>
              </el-form-item>
              <el-form-item label="法律邮箱" prop="legal_email">
                <el-tooltip
                  content="关联条款：Infringement Policy"
                  effect="light"
                  placement="right"
                  style="left: 70px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input v-model="formData.legal_email"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="telphone">
                <el-tooltip
                  content="关联条款：Contact Us"
                  effect="light"
                  placement="right"
                  style="left: 70px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input v-model="formData.telphone"></el-input>
              </el-form-item>
              <el-form-item label="联系地址" prop="address">
                <el-tooltip
                  content="关联条款：Contact Us"
                  effect="light"
                  placement="right"
                  style="left: 70px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input type="textarea" v-model="formData.address" :rows="4"></el-input>
              </el-form-item>
            </m-form-skeleton>
          </div>
        </div>
        <div class="form-module">
          <div class="module-title">运输信息</div>
          <div class="inputs-wrap">
            <m-form-skeleton :loading="pageLoading" :count="2">
              <el-form-item label="订单处理时长说明" prop="process_time">
                <el-tooltip
                  content="关联条款：Shipping Methods"
                  effect="light"
                  placement="right"
                  style="left: 126px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input v-model="formData.process_time"></el-input>
              </el-form-item>
              <el-form-item label="运输方式" prop="transport_type">
                <el-tooltip
                  content="关联条款：Shipping Methods"
                  effect="light"
                  placement="right"
                  style="left: 70px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input v-model="formData.transport_type"></el-input>
              </el-form-item>
              <el-form-item label="预计运输时长说明" prop="transport_time">
                <el-tooltip
                  content="关联条款：Shipping Methods"
                  effect="light"
                  placement="right"
                  style="left: 126px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input v-model="formData.transport_time"></el-input>
              </el-form-item>
              <el-form-item label="运费说明" prop="Shipping_instructions">
                <el-tooltip
                  content="关联条款：Shipping Methods"
                  effect="light"
                  placement="right"
                  style="left: 70px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <el-input
                  type="textarea"
                  v-model="formData.Shipping_instructions"
                  :rows="4"
                ></el-input>
              </el-form-item>
            </m-form-skeleton>
          </div>
        </div>
      </el-form>
    </main>
    <footer>
      <m-form-footer text="保存" @save="submit" :loading="saveLoading" />
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';

import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MFormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';
import MFormFooter from '@/components/formFooter/FormFooter.vue';
import { getDefaultData, saveConfig } from '../api/termListConfig';
import { getRoute } from '../../../tools/config';

import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
export default {
  name: 'TermListConfig',
  components: {
    'm-page-header': MPageHeader,
    'm-form-skeleton': MFormSkeleton,
    'm-form-footer': MFormFooter,
  },
  setup() {
    // 初始化 表单数据
    const formData = ref({});
    const pageLoading = ref(false);
    const getConfig = async () => {
      const res = await getDefaultData(pageLoading);

      const { status, data } = res;

      if (status === 0) return;
      formData.value = data;
    };

    const formInstance = ref(null); // 表单实例
    const saveLoading = ref(false);
    const router = useRouter();
    const listRouter = getRoute('term-list');
    const submit = () => {
      formInstance.value.validate(async valid => {
        if (valid) {
          const data = formData.value;
          data.id = data.id || 0;

          // 提交
          const { status, msg } = await saveConfig(data, saveLoading);

          if (status === 1) {
            ElMessage.success({
              center: true,
              message: msg,
            });
            router.push(listRouter);
          }
        }
      });
    };

    // 定义 验证规则
    const domainNameRule = (rule, val, callback) => {
      if (!/^[A-Za-z0-9]+\.[A-Za-z0-9]+[/=?%\-&_~`@[\]':+!]*([^<>""])*$/.test(val)) {
        callback(new Error('域名格式不正确'));
      }
      callback();
    };

    return {
      pageLoading,
      formData,
      formInstance,

      submit,
      getConfig,
      saveLoading,
      formRule: {
        domain_name: [
          {
            required: true,
            message: '请输入域名',
            trigger: 'blur',
          },
          {
            validator: domainNameRule,
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur',
          },
        ],
        legal_email: [
          {
            required: true,
            message: '请输入法律邮箱',
            trigger: 'blur',
          },
        ],
        telphone: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: '请输入联系地址',
            trigger: 'blur',
          },
        ],
        process_time: [
          {
            required: true,
            message: '请输入订单时长说明',
            trigger: 'blur',
          },
        ],
        transport_type: [
          {
            required: true,
            message: '请输入运输方式',
            trigger: 'blur',
          },
        ],
        transport_time: [
          {
            required: true,
            message: '请输入预计运输时长说明',
            trigger: 'blur',
          },
        ],
        Shipping_instructions: [
          {
            required: true,
            message: '请输入运费说明',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    this.getConfig();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool';
.data-page {
  main {
    padding: 20px;
    .form-module {
      margin-bottom: 10px;
      .module-title {
        display: flex;
        height: 40px;
        align-items: center;
      }
      .inputs-wrap {
        background-color: $formBg;
        padding: 20px;
        border-radius: 5px;
        .el-form-item {
          width: 540px;

          .el-select {
            width: 100%;
          }
        }
      }
    }
  }
}
.el-icon-question {
  position: absolute;
  top: -32px;
}
</style>
